<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <ListSkeleton v-if="listSkeleton" />
          <v-card-text class="text-h5 pa-0" v-else>
            <v-data-table
              :height="accountdetails && accountdetails.length > 0 ? '355' : ''"
              :style="{ maxHeight: !trialBalanceList.data ? '500px' : '' }"
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="headers"
              :items="accountdetails"
              item-class="py-4"
              fixed-header
              :items-per-page="limitData"
              :page.sync="page"
              :search="search"
              :loading="isProgressCircular"
              :sort-by.sync="sortByValue"
              :sort-desc.sync="sortDescValue"
              @pagination="newPagination"
              @dblclick:row="accountingCardDialogOpen"
              @page-count="pageCount = $event"
              @update:sort-by="(val) => buildUrl(val, 'sortKey')"
              @update:sort-desc="(val) => buildUrl(val, 'sortDec')"
            >
              <template v-slot:loading>
                <v-overlay>
                  <v-icon size="64" class="hourglass-icon" color="primary"
                    >mdi-timer-sand-paused</v-icon
                  >
                </v-overlay>
              </template>
              <template v-slot:[`item.totaldebit`]="{ item }">
                <span
                  v-if="accessRight.includes('financial') && item.totaldebit"
                  style="direction: ltr"
                  :class="!item.accountNo ? 'font-weight-bold' : ''"
                  class="d-inline-block"
                  >{{ numberWithCommas(item.totaldebit) }}</span
                >
              </template>
              <template v-slot:[`item.totalcredit`]="{ item }">
                <span
                  v-if="accessRight.includes('financial') && item.totalcredit"
                  style="direction: ltr"
                  :class="!item.accountNo ? 'font-weight-bold' : ''"
                  class="d-inline-block"
                  >{{ numberWithCommas(item.totalcredit) }}</span
                >
              </template>
              <template v-slot:[`item.difference`]="{ item }">
                <span
                  v-if="accessRight.includes('financial')"
                  style="direction: ltr"
                  :class="!item.accountNo ? 'font-weight-bold' : ''"
                  class="d-inline-block"
                  >{{
                    item.difference ? numberWithCommas(item.difference) : ""
                  }}</span
                >
              </template>
              <template v-slot:[`item.accountNo`]="{ item }">
                <span
                  v-if="
                    !item.accountName &&
                    !item.totalcredit &&
                    !item.accountName &&
                    !item.totaldebit
                  "
                  style="direction: ltr"
                  class="font-weight-bold d-inline-block"
                  >{{
                    accountNameHebrew.includes(item.accountNo) && item.accountNo
                      ? $t(`accountingCardReport.${item.accountNo}`)
                      : item.accountNo
                  }}
                  {{
                    item.opening_balance
                      ? "- " +
                        $t("accountingCardReport.showOpeningBalance") +
                        " " +
                        numberWithCommas(item.opening_balance)
                      : null
                  }}</span
                >
                <span v-else style="direction: ltr">{{ item.accountNo }}</span>
              </template>
              <template v-slot:[`item.accountName`]="{ item }">
                <span
                  v-if="accessRight.includes('financial')"
                  style="direction: ltr"
                  :class="!item.accountNo ? 'font-weight-bold' : ''"
                  class="d-inline-block"
                  >{{
                    accountNameHebrew.includes(item.accountName) &&
                    item.accountName
                      ? $t(`accountingCardReport.${item.accountName}`)
                      : item.accountName
                  }}</span
                >
              </template>
              <template v-slot:top>
                <v-card-title class="py-1 primary--text">
                  <v-icon color="primary">mdi-filter-cog-outline</v-icon>
                  <h6 class="ms-2 font-weight-regular">
                    {{ $t("trialBalance.filters") }}
                  </h6>
                  <v-spacer />
                  <HtmlToPdf
                    class="me-2 mb-1"
                    :disabled="!!(accountdetails && accountdetails.length > 0)"
                    :model="trialBalanceList"
                    :payload="payload"
                    type="TrialBalanceReportPDF"
                  />
                  <ExcelAndResetFilterCommon
                    :data="accountdetails.length > 0"
                    @downloadExcelFile="downloadExcelFile"
                    @resetFilters="resetFilters"
                  />
                </v-card-title>
                <v-divider />
                <v-form v-model="valid" ref="form">
                  <v-card-text>
                    <v-row :dense="true">
                      <v-col cols="5">
                        <v-row :dense="true">
                          <v-col cols="12" class="d-flex">
                            <v-menu
                              ref="invoiceStartDateMenu"
                              v-model="invoiceStartDateMenu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  hide-details="auto"
                                  dense
                                  class="me-2"
                                  v-model="formattedFromDate"
                                  :label="$t('trialBalance.fromDate')"
                                  @input="buildUrl"
                                  readonly
                                  :rules="
                                    profileById.date_format
                                      ? dateValid
                                      : dateValid2
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="model.startDate"
                                @input="buildUrl"
                                @change="
                                  $refs.invoiceStartDateMenu.save(
                                    model.startDate
                                  )
                                "
                              >
                                <v-btn
                                  small
                                  class="primary"
                                  @click="
                                    $refs.invoiceStartDateMenu.save(
                                      getStartDate()
                                    )
                                  "
                                  >{{ $t("route.today") }}</v-btn
                                >
                              </v-date-picker>
                            </v-menu>
                            <v-menu
                              ref="invoiceEndDateMenu"
                              v-model="invoiceEndDateMenu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  hide-details="auto"
                                  dense
                                  v-model="formattedToDate"
                                  :label="$t('trialBalance.toDate')"
                                  @input="buildUrl"
                                  readonly
                                  :rules="
                                    profileById.date_format
                                      ? dateValid
                                      : dateValid2
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="model.endDate"
                                @input="buildUrl"
                                @change="
                                  $refs.invoiceEndDateMenu.save(model.endDate)
                                "
                              >
                                <v-btn
                                  small
                                  class="primary"
                                  @click="
                                    $refs.invoiceEndDateMenu.save(getEndDate())
                                  "
                                  >{{ $t("route.today") }}</v-btn
                                >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" class="d-flex"> </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="4"> </v-col>
                      <v-col cols="3">
                        <v-row :dense="true">
                          <v-col cols="12" class="d-flex">
                            <v-autocomplete
                              hide-details="auto"
                              class="my-2"
                              height="30%"
                              dense
                              v-model="selectedGroup"
                              :items="groupValue"
                              item-text="name"
                              item-value="id"
                              clearable
                              flat
                              @input="buildUrl"
                              return-object
                              single-line
                              :label="
                                $t('accountingCardReport.balanceSheetSection')
                              "
                            />
                          </v-col>
                          <v-col cols="12" class="d-flex"> </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row dense class="my-2">
                      <v-col cols="12">
                        <v-checkbox
                          dense
                          v-model="show_opening_balance"
                          :label="$t('accountingCardReport.showOpeningBalance')"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense class="my-2">
                      <v-col cols="12" class="text-center">
                        <ExecuteAndRestCommon
                          :valid="valid"
                          @reset="
                            $store.commit('trialBalance/SET_TRIALBALANCE', [])
                          "
                          @paginate="paginate(false)"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-card-title class="py-1 primary--text">
                  <v-row dense no-gutters>
                    <v-col cols="9" class="d-flex">
                      <v-icon color="primary">mdi-view-list-outline</v-icon>
                      <h6 class="ms-2 align-self-center font-weight-regular">
                        {{ $t("trialBalance.trialBalanceReport") }}
                      </h6>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        ref="search"
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider />
              </template>
              <template v-slot:no-data>
                <v-card-text class="text-caption text-center">
                  {{ $t("common.noDataFound") }}</v-card-text
                >
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex mt-n6">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination
                        :total-visible="5"
                        v-model="page"
                        :length="trialBalanceList.data ? pageCount : 0"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
            <v-divider />
            <v-row>
              <v-col cols="3">
                <span class="ps-3 pt-2 text-caption"
                  >{{ $t("common.numberOfTotalRows") }}
                  {{
                    trialBalanceList && trialBalanceList.totalRows
                      ? numberOfRows
                      : 0
                  }}</span
                >
              </v-col>
              <v-col cols="3">
                <v-row dense>
                  <v-col
                    class="text-end font-weight-bold"
                    :class="locale === 'he' ? 'text-start' : 'text-end'"
                  >
                    <span class="pt-2 text-body-2 font-weight-bold">
                      {{ $t("trialBalance.totalCreditAmount") }}
                      {{
                        trialBalanceList && trialBalanceList.totalCreditAmount
                          ? numberWithCommas(trialBalanceList.totalCreditAmount)
                          : 0
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="3">
                <v-row dense>
                  <v-col
                    class="font-weight-bold"
                    :class="locale === 'he' ? 'text-start' : 'text-end'"
                  >
                    <span class="ps-6 pt-2 text-body-2 font-weight-bold">
                      {{ $t("trialBalance.totalDebitAmount") }}
                      {{
                        trialBalanceList && trialBalanceList.totalDebitAmount
                          ? numberWithCommas(trialBalanceList.totalDebitAmount)
                          : 0
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="3">
                <v-row dense>
                  <v-col
                    class="font-weight-bold"
                    :class="locale === 'he' ? 'text-start' : 'text-end'"
                  >
                    <span class="ps-6 pt-2 text-body-2 font-weight-bold">
                      {{ $t("trialBalance.totalDifference") }}
                      {{
                        trialBalanceList && trialBalanceList.totalDifference
                          ? numberWithCommas(trialBalanceList.totalDifference)
                          : 0
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <AccountingCardRecordsDialog
        :accountingCardDialog.sync="accountingCardDialog"
        :modelData="modelData"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import { formatDateDDMMYYYY, parseDateYYYYMMDD } from "@/utils";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import exportFromJSON from "export-from-json";
import AccountingCardRecordsDialog from "@/components/accountingCardRecords/Dialogs/AccountingCardRecordsDialog.vue";
import ExcelAndResetFilterCommon from "@/components/ExcelAndResetFilterCommon";
import ExecuteAndRestCommon from "@/components/ExecuteAndRestCommon";
import { validationMixin } from "@/mixins/validationMixin";

export default {
  name: "TrialBalanceReport",
  components: {
    ListSkeleton,
    HtmlToPdf,
    AccountingCardRecordsDialog,
    ExcelAndResetFilterCommon,
    ExecuteAndRestCommon,
  },
  mixins: [validationMixin],
  data() {
    return {
      sortByValue: null,
      sortDescValue: null,
      limitData: this.limit,
      limit: this.pageChange?.limit ? Number(this.pageChange?.limit) : 10,
      page: this.pageChange?.page ? Number(this.pageChange?.page) : 1,
      pagination: null,
      accessRights: [],
      search: "",
      listSkeleton: false,
      invoiceStartDateMenu: false,
      invoiceEndDateMenu: false,
      model: {
        startDate: this.$route.query.startDate || null,
        endDate: this.$route.query.endDate || null,
      },
      valid: false,
      numberOfRows: "",
      accountdetails: [],
      accountingCardDialog: false,
      modelData: null,
      selectedGroup: {},
      accountNameHebrew: [
        "Income",
        "Expenses",
        "Funds",
        "Banks",
        "Others",
        "Employee",
        "Customer",
        "Supplier",
        "Item",
        "Bank",
        "Checks",
        "Cash",
        "VAT",
        "VATExemptedIncome",
        "OverseasIncome",
        "CustomerWithholdingTax",
        "PurchasesVAT",
        "SupplierWithholdingTax",
        "EquipmentPurchaseVAT",
        "ChequeFunds",
        "CashFunds",
        "IncomeVAT",
        "Cheque",
        "ReceiptTaxtInvoice",
      ],
      show_opening_balance: true,
      isProgressCircular: false,
    };
  },
  computed: {
    groupValue() {
      return [
        { id: 1, name: this.$t("accountingCardReport.Employee") },
        { id: 2, name: this.$t("accountingCardReport.Customer") },
        { id: 3, name: this.$t("accountingCardReport.Supplier") },
        { id: 4, name: this.$t("accountingCardReport.Item") },
        {
          id: 5,
          name: this.$t("accountingCardReport.Income"),
          account_type: 0,
        },
        {
          id: 6,
          name: this.$t("accountingCardReport.Expenses"),
          account_type: 1,
        },
        { id: 7, name: this.$t("accountingCardReport.Funds"), account_type: 2 },
        { id: 8, name: this.$t("accountingCardReport.Banks"), account_type: 4 },
        {
          id: 9,
          name: this.$t("accountingCardReport.Others"),
          account_type: 3,
        },
      ];
    },
    formattedFromDate: {
      get() {
        return this.profileById.date_format
          ? parseDateYYYYMMDD(this.model.startDate)
          : formatDateDDMMYYYY(this.model.startDate);
      },
      set(val) {
        if (val.length == 10) {
          this.model.startDate = this.profileById.date_format
            ? this.parseDateYYYYMMDD(val)
            : formatDateDDMMYYYY(val);
        }
      },
    },
    formattedToDate: {
      get() {
        return this.profileById.date_format
          ? parseDateYYYYMMDD(this.model.endDate)
          : formatDateDDMMYYYY(this.model.endDate);
      },
      set(val) {
        if (val.length == 10) {
          this.model.endDate = this.profileById.date_format
            ? this.parseDateYYYYMMDD(val)
            : formatDateDDMMYYYY(val);
        }
      },
    },
    params() {
      if (this.pagination) {
        return {
          ...this.pagination,
        };
      } else {
        return {};
      }
    },
    payload() {
      return {
        from_reference_one_date: this.model.startDate,
        to_reference_two_date: this.model.endDate,
      };
    },
    ...mapGetters({
      trialBalanceList: "trialBalance/trialBalanceList",
      locale: "locale",
      accessRight: "accessRight",
      profileById: "profile/profileById",
      pageChange: "trialBalancePagination/pageChange",
    }),
    headers() {
      return [
        {
          text: this.$t("trialBalance.accountNo"),
          align: "start",
          value: "accountNo",
          width: "125px",
          class: "px-1",
          sortable: false,
        },
        {
          text: this.$t("trialBalance.accountName"),
          value: "accountName",
          width: "140px",
          class: "px-2",
          sortable: false,
        },
        {
          text: this.$t("trialBalance.debit"),
          value: "totaldebit",
          width: "100px",
          class: "px-2",
          sortable: false,
        },
        {
          text: this.$t("trialBalance.credit"),
          value: "totalcredit",
          width: "100px",
          class: "px-2",
          sortable: false,
        },
        {
          text: this.$t("trialBalance.difference"),
          value: "difference",
          width: "100px",
          class: "px-2",
          sortable: false,
        },
      ];
    },
  },
  async created() {
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
  },
  async mounted() {
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
    this.$store.commit(
      "trialBalance/SET_TRIALBALANCE_QUERY",
      this.$route.query
    );
    if (Object.keys(this.$route.query).length) {
      this.paginate(false);
    } else {
      const self = this;
      self.getNow();
    }
    await this.$store.dispatch("profile/GetCompanyById");
    this.listSkeleton = false;
  },
  beforeDestroy() {
    this.$store.commit("trialBalancePagination/SHOW_PAGE_CHANGE", {
      page: this.pagination?.page,
      limit: this.pagination?.itemsPerPage,
    });
  },
  destroyed() {
    this.$store.commit("trialBalance/SET_TRIALBALANCE", []);
  },
  watch: {
    trialBalanceList: {
      handler(newVal) {
        this.accountdetails = [];
        if (newVal.data && newVal.data.length > 0) {
          newVal.data.forEach((item) => {
            item.accountdetails.forEach((dd) => {
              this.accountdetails.push(dd);
            });
            this.accountdetails.push({
              accountName: item.category,
              totaldebit: item.totalDebit,
              totalcredit: item.totalCredit,
              difference: item.difference,
            });
          });
        }
      },
      deep: true,
    },
    "$route.query": {
      handler(newVal, oldVal) {
        if (
          JSON.stringify(newVal) !== JSON.stringify(oldVal) ||
          !Object.keys(this.$route.query).length
        )
          this.model.startDate = this.$route.query.startDate || null;
        this.model.endDate = this.$route.query.endDate || null;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    newPagination(val) {
      this.pagination = val;
      this.numberOfRows = val.itemsLength;
    },
    accountingCardDialogOpen(_, item) {
      if (item.item.accountNo && item.item.difference) {
        this.accountingCardDialog = true;
        this.modelData = item.item;
      } else {
        this.accountingCardDialog = false;
      }
    },
    getStartDate() {
      const today = new Date();
      this.model.startDate = today.toISOString().substring(0, 10);
    },
    getEndDate() {
      const today = new Date();
      this.model.endDate = today.toISOString().substring(0, 10);
    },
    resetFilters() {
      this.sortByValue = null;
      this.sortDescValue = null;
      this.search = null;
      this.selectedGroup = null;
      this.show_opening_balance = true;
      this.$store.commit("trialBalance/SET_TRIALBALANCE_QUERY", {});
      this.buildUrl();
    },
    downloadExcelFile() {
      let self = this;
      let res = [];
      self.trialBalanceList.data.forEach((el) => {
        el.accountdetails.map((o) =>
          res.push(
            Object.fromEntries(self.headers.map((k) => [k.text, o[k.value]]))
          )
        );
      });
      exportFromJSON({
        data: res,
        fileName: "trialBalanceReport",
        exportType: exportFromJSON.types.xls,
      });
    },
    getNow() {
      const currentYear = new Date().getFullYear();
      const start_date = new Date(currentYear, 0, 1).getDate();
      const end_date = new Date(currentYear, 11, 31).getDate();
      const start_month = new Date(currentYear, 0, 1).getMonth() + 1;
      const end_month = new Date(currentYear, 11, 31).getMonth() + 1;
      const current_year = new Date().getUTCFullYear();
      this.model.startDate =
        current_year + "-" + 0 + start_month + "-" + 0 + start_date;
      this.model.endDate = current_year + "-" + end_month + "-" + end_date;
      this.buildUrl();
    },
    numberWithCommas(x) {
      return x
        ? parseFloat(x)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : (0).toFixed(2);
    },
    buildUrl(val) {
      let obj = {};
      if (typeof val === "string" || typeof val === "object") {
        // this.page = 1;
        // obj.page = 1;
        // this.params.page = 1;
      }
      obj.order_by = this.sortByValue;
      obj.startDate = this.model.startDate;
      obj.endDate = this.model.endDate;
      obj.sortDec = this.sortDescValue;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === "custId" && val === null) return acc;
        else if (key === "invoiceStartDate" && val === null) return acc;
        else if (key === "invoiceEndDate" && val === null) return acc;
        else if (key === "order_by" && val === null) return acc;
        else if (key === "sortDec" && val === null) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$router
        .push({
          name: "TrialBalance",
          query: {
            ...obj,
          },
        })
        .catch(() => {});
      this.$store.commit("trialBalance/SET_TRIALBALANCE_QUERY", obj);
    },
    async paginate() {
      // make params for where_and
      let concateWhereAnd = "";
      let whereAndVal = "|";
      // filter with invoice date
      concateWhereAnd += this.model.startDate ? "from_reference_one_date," : "";
      concateWhereAnd += this.model.endDate ? "to_reference_two_date," : "";
      whereAndVal += this.model.startDate ? this.model.startDate + "," : "";
      whereAndVal += this.model.endDate ? this.model.endDate + "," : "";
      const payload = {
        type: this.selectedGroup?.name,
        whereType: this.selectedGroup?.account_type,
        openingbalance: this.show_opening_balance,
        where_and:
          concateWhereAnd.slice(0, -1) && whereAndVal.slice(0, -1)
            ? concateWhereAnd.slice(0, -1) + whereAndVal.slice(0, -1)
            : undefined,
      };
      if (payload.whereType) {
        payload.type, (payload.whereType = "account_type," + payload.whereType);
      } else {
        if (payload.whereType == 0) {
          payload.type, (payload.whereType = "account_type," + 0);
        }
      }
      this.isProgressCircular = true;
      // end make params for where_and
      await this.$store
        .dispatch("trialBalance/GetTrialBalanceReport", payload)
        .then(() => {
          this.isProgressCircular = false;
          this.$refs.search.focus();
        });
    },
  },
};
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
</style>
